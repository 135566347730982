var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "calc(100vh - 40px)", "overflow-y": "auto" } },
    [
      _c("el-form", { attrs: { inline: true, model: _vm.queryData } }, [
        _c(
          "div",
          { staticClass: "row-bg" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { lg: 5, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: { clearable: "", placeholder: "请输入作者" },
                          model: {
                            value: _vm.queryData.author,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "author", $$v)
                            },
                            expression: "queryData.author",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 5, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: {
                            clearable: "",
                            placeholder: "请输入任务名称",
                          },
                          model: {
                            value: _vm.queryData.taskName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "taskName", $$v)
                            },
                            expression: "queryData.taskName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 5, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: {
                            clearable: "",
                            placeholder: "请输入作品名称",
                          },
                          model: {
                            value: _vm.queryData.creationName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "creationName", $$v)
                            },
                            expression: "queryData.creationName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 5, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: {
                            clearable: "",
                            placeholder: "请输入举报次数（结果大于输入次数）",
                          },
                          model: {
                            value: _vm.queryData.reports,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "reports", $$v)
                            },
                            expression: "queryData.reports",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.reset()
                          },
                        },
                      },
                      [_vm._v("重 置")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("查 询")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-card",
        {
          staticStyle: { "overflow-y": "scroll", height: "calc(100% - 80px)" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "align-items": "center" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("作品数据")]),
              _c("div", { staticClass: "flex_1" }),
              _c("text-button", {
                attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.search.apply(null, arguments)
                  },
                },
              }),
              _c("text-button", {
                attrs: {
                  icon: "el-icon-setting",
                  contentTit: "列设置",
                  colSetting: _vm.colSetting,
                  ispopover: true,
                  baseColSetting: _vm.baseColSetting,
                },
                on: { update: _vm.update },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: _vm.tableKey,
              attrs: {
                "default-sort": { prop: "reportNum", order: "descending" },
                fit: "",
                "highlight-current-row": true,
                data: _vm.dataList,
              },
              on: { "sort-change": _vm.tableSortAll },
            },
            _vm._l(_vm.defaultHeader, function (item) {
              return _c("el-table-column", {
                key: item.label,
                attrs: {
                  label: item.label,
                  align: item.align ? item.align : "left",
                  "min-width": item.width ? item.width : "auto",
                  sortable: item.sortable ? item.sortable : false,
                  prop: item.prop,
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.label === "举报次数"
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass: "column-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickReportNum(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.reportNum))]
                                ),
                              ]
                            : item.label === "作品状态"
                            ? [
                                _c("span", {
                                  staticClass: "span-state",
                                  style:
                                    "backgroundColor" +
                                    ":" +
                                    _vm.getWorksColor(scope.row.status),
                                }),
                                _c(
                                  "span",
                                  {
                                    style:
                                      "color" +
                                      ":" +
                                      _vm.getWorksColor(scope.row.status),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.status == "正常"
                                          ? "上架作品"
                                          : "下架作品"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            : item.label === "操作"
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        scope.row.status == "正常"
                                          ? false
                                          : true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewWorks(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看作品 ")]
                                ),
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: `确定要将该数据改为‘${
                                        _vm.statusOptions[scope.row.status]
                                      }’状态吗？`,
                                    },
                                    on: {
                                      onConfirm: function ($event) {
                                        return _vm.offShelfWorks(scope.row)
                                      },
                                      confirm: function ($event) {
                                        return _vm.offShelfWorks(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "8px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.statusOptions[
                                                scope.row.status
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[item.prop])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.viewWorksDialog
        ? _c("viewWorks", {
            attrs: { show: _vm.viewWorksDialog, editData: _vm.editData },
            on: {
              close: function ($event) {
                return _vm.closeViewWork()
              },
              getWorksCreationList: _vm.getWorksCreationList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }