<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false">
    <div slot="title"
        class="dia-tit">
      <i></i>
      <span>举报详情</span>
    </div>
    <div>
      <el-table :data="dataList">
        <el-table-column property="reportMsg" align="center" label="举报原因" width=""></el-table-column>
        <el-table-column property="updateTime" align="center" label="举报时间" width="200"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          >
          <template slot-scope="scope">
            <!-- <el-button  @click="ignoreReporting(scope.row.id)" type="text" size="small">忽略举报</el-button> -->
            <!-- <el-button type="text" size="small" @click="offShelfWorks()">下架作品</el-button> -->
            <el-popconfirm
              title="确定忽略该举报吗？"
              @onConfirm="ignoreReporting(scope.row.id)"
              @confirm="ignoreReporting(scope.row.id)"
            >
              <el-button
                slot="reference"
                style="margin-left: 8px"
                type="text"
              >
                忽略举报
              </el-button>
            </el-popconfirm>
            <el-popconfirm
              @onConfirm="offShelfWorks"
              @confirm="offShelfWorks"
              :title="`确定要将该数据改为‘${
                                  statusOptions[editData.status]
                                }’状态吗？`"
            >
              <el-button
                slot="reference"
                style="margin-left: 8px"
                type="text"
              >
                {{ statusOptions[editData.status] }}
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */
import { $getWorkslistCreationItemInfo ,$getWorksCancleChangeCreationNum ,$getWorksDisabled }  from "@/api/works";
export default {
  data(){
    return{
      statusOptions: {
        "正常": "下架作品",
        "已禁用": "上架作品",
      },
      dataList: [],
    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  watch: { },
  mounted() {
    this.getWorkslistCreationItemInfo()
  },
  methods: {
    async getWorkslistCreationItemInfo () {
      const res = await $getWorkslistCreationItemInfo({
        pageNum:1,
        pageSize:1000,
        needCount:true,
        creationId:this.editData.creationId,
        type:'举报'
      })
      this.dataList = res.body.list
    },
    openDialog() {
    },
    // 关闭弹窗
    close () {
      this.$emit('close')
    },
    async ignoreReporting(id){
      const res = await $getWorksCancleChangeCreationNum({
        creationItemId:id,
      })
      if(res.state == 'success'){
        this.$message.success("忽略举报成功");
        this.getWorkslistCreationItemInfo()
      }

    },
    async offShelfWorks(){
      const res = await $getWorksDisabled({
        id:this.editData.creationId,
        status:this.editData.status == "正常" ? "已禁用" : '正常',
      })
      if(res.state == 'success'){
        this.$message.success(this.editData.status == "正常" ? "下架成功" : '上架成功');
        this.$emit('getWorksCreationList')
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
</style>
