var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("举报详情")])]
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            { attrs: { data: _vm.dataList } },
            [
              _c("el-table-column", {
                attrs: {
                  property: "reportMsg",
                  align: "center",
                  label: "举报原因",
                  width: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "updateTime",
                  align: "center",
                  label: "举报时间",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定忽略该举报吗？" },
                            on: {
                              onConfirm: function ($event) {
                                return _vm.ignoreReporting(scope.row.id)
                              },
                              confirm: function ($event) {
                                return _vm.ignoreReporting(scope.row.id)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(" 忽略举报 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: `确定要将该数据改为‘${
                                _vm.statusOptions[_vm.editData.status]
                              }’状态吗？`,
                            },
                            on: {
                              onConfirm: _vm.offShelfWorks,
                              confirm: _vm.offShelfWorks,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.statusOptions[_vm.editData.status]
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }