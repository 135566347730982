<template>
  <div style="height: calc(100vh - 40px); overflow-y: auto">
    <el-form :inline="true" :model="queryData">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="">
              <el-input
                clearable
                v-model="queryData.author"
                placeholder="请输入作者"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="">
              <el-input
                clearable
                v-model="queryData.taskName"
                placeholder="请输入任务名称"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="">
              <el-input
                clearable
                v-model="queryData.creationName"
                placeholder="请输入作品名称"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="">
              <el-input
                clearable
                v-model="queryData.reports"
                placeholder="请输入举报次数（结果大于输入次数）"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="reset()">重 置</el-button>
            <el-button type="primary" @click="search()">查 询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card
      shadow="never"
      style="overflow-y: scroll; height: calc(100% - 80px)"
    >
      <div slot="header" class="flex" style="align-items: center">
        <span>作品数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'reportNum', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        @sort-change="tableSortAll"
        :data="dataList"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '举报次数'">
              <span class="column-link" @click="clickReportNum(scope.row)">{{
                  scope.row.reportNum
              }}</span>
            </template>
            <template v-else-if="item.label === '作品状态'">
              <span
                class="span-state"
                :style=" 'backgroundColor' +':' +getWorksColor(scope.row.status)"
              ></span>
              <span  :style=" 'color' +':' + getWorksColor(scope.row.status)">{{ scope.row.status == '正常' ? '上架作品':'下架作品'}}</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button
                style="margin-left: 8px"
                type="text"
                @click="viewWorks(scope.row)"
                :disabled='scope.row.status== "正常" ? false : true'
              >
                查看作品
              </el-button>
              <el-popconfirm
                @onConfirm="offShelfWorks(scope.row)"
                @confirm="offShelfWorks(scope.row)"
                :title="`确定要将该数据改为‘${
                                  statusOptions[scope.row.status]
                                }’状态吗？`"
              >
                <el-button
                  slot="reference"
                  style="margin-left: 8px"
                  type="text"
                >
                  {{ statusOptions[scope.row.status] }}
                </el-button>
              </el-popconfirm>
              
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <!-- 查看举报 dialog -->
    <viewWorks
      :show="viewWorksDialog"
      v-if="viewWorksDialog"
      @close="closeViewWork()"
      :editData="editData"
      @getWorksCreationList='getWorksCreationList'
    />
  </div>
</template>

<script>
import viewWorks from "./viewWorks";
import getWorksColor from "@/mixins/getStateColor";
import TextButton from "@/components/TextButton";
import { $getWorksCreationList,$getWorksDisabled ,$getInformation} from "@/api/works";

// 默认展示列
const baseColSetting = [
  {
    label: "作者",
    prop: "author",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "任务名称",
    prop: "taskName",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "作品名称",
    prop: "creationName",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "访问次数",
    prop: "viewNum",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "举报次数",
    prop: "",
    state: true,
    sortable: true,
    align: "center",
    width: "150px",
  },
  {
    label: "作品状态",
    prop: "",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getWorksColor],
  components: {
    TextButton,
    viewWorks
  },
  data() {
    return {
      viewWorksDialog:false,
      tableKey: 1,
      queryData: {
        inputName: null,
      },
      editData:{},
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      orderBy: 'reportNum', // 排序依据
      isAsc: false, // 默认为升序
      statusOptions: {
        "正常": "下架作品",
        "已禁用": "上架作品",
      },
    };
  },
  created() {
    this.getWorksCreationList()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    async getCatalogTree() {
      const res = await $getCatalogTree({
        group:'培训'
      });
      this.catalogIdList = res.body;
    },
    // 获取作品列表
    async getWorksCreationList() {
      const res = await $getWorksCreationList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        orderBy: this.orderBy,
        isAsc: this.isAsc,
        ...this.queryData
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },
    clickReportNum(row){
      if(row.reportNum > 0){
        this.viewWorksDialog = true;
        this.editData = row
      }else{
        this.$message.info("未被举报过");
      }
    },
    closeViewWork(){
      this.viewWorksDialog = false;
    },
    async viewWorks(row){
      const res = await $getInformation({
        creationId:row.creationId
      });
      if(res && res.body && res.body.sourceCodeFile){
        window.open(location.origin + '/scratch3/player.html?workUrl='+ res.body.sourceCodeFile,'_blank')
      }else{
        this.$message.info("作品已被下架");
      }
    },
    async offShelfWorks(row){
      const res = await $getWorksDisabled({
        id:row.creationId,
        status:row.status == "正常" ? "已禁用" : '正常',
      })
      if(res.state == 'success'){
        this.$message.success(row.status == "正常" ? "下架成功" : '上架成功');
        this.getWorksCreationList()
      }
    },
    // 重置
    reset() {
      this.queryData = {};
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getWorksCreationList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getWorksCreationList();
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getWorksCreationList();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    tableSortAll (column) {
        switch (column.prop) {
          case 'reportNum':
            this.orderBy = 'reportNum'
            break
        }
        switch (column.order) {
          case 'ascending':
            this.isAsc = true
            break
          case 'descending':
            this.isAsc = false
        }
        this.getWorksCreationList()
      },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/table.scss";
@import "@/style/container.scss";
</style>
